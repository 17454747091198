export default [
  {
    title: 'Purchases',
    icon: 'shopping-cart',
    children: [
      {
        title: 'Dashboard',
        route: 'Purchases dashboard',
        resource: 'PurchasesDashboard',
        action: 'manage',
      },
      {
        title: 'Orders',
        route: 'Orders'
      },
      {
        title: 'Expenses',
        route: 'Incoming invoices'
      },
      {
        title: 'Expenses - Beta',
        route: 'Supplier invoices'
      },
    ]
  },
]
