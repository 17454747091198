export default [
  {
    title: 'AccountingBeta',
    icon: 'chart-line',
    resource: 'AccountingDashboard',
    action: 'manage',
    children: [
      {
        title: 'Dashboard',
        route: 'AccountingDashboard',
        resource: 'AccountingDashboard',
        action: 'manage',
      },
      {
        title: 'CustomerReceivable',
        route: 'CustomerReceivable2',
        resource: 'CustomerReceivable',
        action: 'manage',
      },
      {
        title: 'SupplierReceivable',
        route: 'SupplierReceivable2',
        resource: 'SupplierReceivable',
        action: 'manage',
      },
      {
        title: 'BankAccounts',
        route: 'BankAccounts',
        resource: 'BankAccounts',
        action: 'manage',
      }
    ]
  },
]
