export default [
  {
    title: 'Support',
    icon: 'life-ring',
    children: [
      {
        title: 'Dashboard',
        route: 'Tickets dashboard',
        resource: 'TicketsDashboard',
        action: 'manage',
      },
      {
        title: 'Support',
        route: 'Tickets'
      },
    ]
  },
]
