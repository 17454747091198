<template>
  <div class="navbar-container d-flex content align-items-center">

    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
        >
          <icon icon="bars"/>
        </b-link>
      </li>
    </ul>

    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">


      <icon
          v-if="isLoading"
          icon="spinner"
          :spin="true"
          size="2x"
          class="text-primary"
      />

      <!--      <bookmarks />-->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale/>
      <!--      <dark-Toggler class="d-none d-lg-block" />-->
      <!--      <search-bar />-->
      <!--      <cart-dropdown />-->
      <!--      <notification-dropdown />-->
      <user-dropdown/>
    </b-navbar-nav>
  </div>
</template>

<script>
// import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
// import SearchBar from './components/SearchBar.vue'
// import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import useAPI from '../../../utils/useAPI'

export default {
  components: {
    Locale,
    // SearchBar,
    // DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup () {
    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { isLoading } = useAPI()

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      isLoading
    }
  }
}
</script>
