export default [
  {
    title: 'Sales',
    icon: 'euro-sign',
    children: [
      {
        title: 'Dashboard',
        route: 'Sales dashboard',
        resource: 'SalesDashboard',
        action: 'manage',
      },
      {
        title: 'Offers',
        route: 'Offers'
      },
      {
        title: 'OutgoingInvoices',
        route: 'OutgoingInvoices'
      },
      {
        title: 'Customer invoices - Beta',
        route: 'Customer invoices'
      },
    ]
  },
]
