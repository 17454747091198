<template>
  <div
      class="vertical-layout h-100 navbar-floating footer-static"
      :class="[layoutClasses]"
      :data-col="isNavMenuHidden ? '1-column' : null"
  >
    <b-navbar
        :toggleable="false"
        class="header-navbar navbar navbar-shadow align-items-center floating-nav"
    >
      <slot
          name="navbar"
          :toggleVerticalMenuActive="toggleVerticalMenuActive"
          :navbarTypeClass="['header-navbar navbar navbar-shadow align-items-center floating']"
      >
        <navbar-layout :toggle-vertical-menu-active="toggleVerticalMenuActive"/>
      </slot>
    </b-navbar>

    <sidebar-layout
        v-if="!isNavMenuHidden"
        :is-vertical-menu-active="isVerticalMenuActive"
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
    >
      <template #header="slotProps">
        <slot
            name="vertical-menu-header"
            v-bind="slotProps"
        />
      </template>
    </sidebar-layout>

    <div
        class="sidenav-overlay"
        :class="overlayClasses"
        @click="isVerticalMenuActive = false"
    />

    <transition
        name="zoom-fade"
        mode="out-in"
    >
      <!--      <component-->
      <!--          :is="layoutContentRenderer"-->
      <!--          :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"-->
      <!--      >-->
      <layout-content>
        <template
            v-for="(index, name) in $scopedSlots"
            v-slot:[name]="data"
        >
          <slot
              :name="name"
              v-bind="data"
          />
        </template>
      </layout-content>
      <!--      </component>-->
    </transition>
    <!--/ Content -->

    <!-- Footer -->
    <footer
        class="footer footer-light footer-static"
    >
      <slot name="footer">
        <!--        <app-footer/>-->
      </slot>
    </footer>
    <!-- /Footer -->

    <slot name="customizer"/>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import NavbarLayout from './components/navbar/NavbarLayout'
// import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@/utils/useAppConfig'
// import { BNavbar } from 'bootstrap-vue'
import LayoutContent from './components/LayoutContent'
// import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
// import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import SidebarLayout from './components/sidebar/SidebarLayout'
import useLayout from './useLayout'
import mixinLayout from './mixinLayout'

export default {
  components: {
    NavbarLayout,
    // AppBreadcrumb,
    // AppNavbarVerticalLayout,
    // AppFooter,
    SidebarLayout,
    // BNavbar,
    // LayoutContentRendererLeftDetached,
    // LayoutContentRendererLeft,
    LayoutContent,
  },
  mixins: [mixinLayout],
  computed: {},
  setup () {
    const {
      // routerTransition, navbarBackgroundColor,
      isNavMenuHidden,
    } = useAppConfig()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      // isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      //   navbarTypeClass,
      //   footerTypeClass,
    } = useLayout()

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      // isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      //   navbarTypeClass,
      //   footerTypeClass,
      //
      //   // App Config
      //   routerTransition,
      //   navbarBackgroundColor,
      isNavMenuHidden,
    }
  }
  ,
}
</script>

<style lang="scss">
@import "~@/assets/scss/base/themes/bordered-layout.scss";
</style>
